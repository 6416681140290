<template lang="html">
  <div class="container">
    <div class="header" v-show="!checkout">
      <h4>Spring Banquet</h4><br>
      <span>2022</span>
      <p>
        The DNIMAS students have informed us that this year's in-person Spring Banquet will be held on April 23rd from 5:00 to 9:00 at the Edmonds Center, located in Portsmouth, VA.   The Edmonds Center banquet hall is actually located in St. Mark Missionary Baptist Church, located on 2714 Frederick Blvd, Portsmouth, VA.  The students are hoping that many of the alumni will be able to attend.
      </p>
      <p>
        Tickets to the banquet are $38 for adults and $20 for children 9 and under.  Babies and Toddlers that don't require a chair will be admitted free.  If you cannot attend, the alumni are asking that you consider making a donation to the event.
      </p>
    </div>
    <div class="donate-box" v-show="!checkout">
      <div class="title">
        <p>Purchase tickets to the</p>
        <h4>Annual Spring Banquet</h4>
      </div>
      <div class="description">
        Please select the number of adult and children tickets needed below.
      </div>
      <div class="amounts">
        <div class="amount" @click="adults++">
          <p>+ Adult Ticket</p>
        </div>
        <div class="amount" @click="children++">
          <p>+ Child Ticket</p>
        </div>
        <div class="amount clear" @click="clearTickets()">
          <p>Clear</p>
        </div>
      </div>
      <div class="total-area">
        <div class="tickets">
          <div class="ticket">
            <p>Adults:</p>
            <input type="text" v-model="adults">
          </div>
          <div class="ticket">
            <p>Children:</p>
            <input type="text" v-model="children">
          </div>
        </div>
        <div class="total">
          <p> Your total: ${{total}}</p>
        </div>
      </div>
      <div class="checkout" @click="toCheckout">
        <p>Proceed to checkout</p><i class="fas fa-arrow-right"></i>
      </div>
    </div>
    <div class="checkout-container" v-show="checkout">
      <form class="card" method="post" @submit.prevent="purchase">
        <h4>Spring Banquet 2022 Tickets</h4>
        <div class="input-container">
          <div class="input-field">
            <p>First name <span class="astrik">*</span></p>
            <input type="text" placeholder="First name" required v-model="firstName">
          </div>
          <div class="input-field">
            <p>Last Name <span class="astrik">*</span></p>
            <input type="text" placeholder="First name" required v-model="lastName">
          </div>
        </div>
        <div class="input-container">
          <div class="input-field large-field">
            <p>Email <span class="astrik">*</span></p>
            <input type="text" placeholder="youremail@gmail.com" required v-model="email">
          </div>
        </div>
        <div class="formTotal">
          <p>Your total today:</p>
          <span>{{ '$' + total }}</span>
        </div>
        <div class="card-input">
          <p>Card Number</p>
          <div id="card-element"></div>
        </div>
        <div class="cancel">
          <div class="return" @click="cancelForm">
            <i class="fas fa-arrow-left"></i>
            <p>Return to membership selection</p>
          </div>
          <button>Complete purchase</button>
        </div>
      </form>
      <div class="stripe-badge">
        <img src="@/assets/stripe-badge-grey.png">
      </div>
    </div>
    <div class="processing" v-show="checkout === 'pending'">
      <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
      </div>
      <p>Processing your payment...</p>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify'
import { useToast } from "vue-toastification"

const stripe = window.Stripe('pk_live_51IioEGJLNlPqFgGD9xjg1zs0Cw8n4740q1uNijpBGnTzS3YIZ9rzwhnsMMuRR7bkKoLZ9DTNJXuvScJdpJMGXJW200j7OxDfz7')
const elements = stripe.elements()
const card = elements.create('card')

export default {
  data () {
    return  {
      adults: 0,
      children: 0,
      checkout: false,
      firstName: '',
      lastName: '',
      email: ''
    }
  },
  computed: {
    total () {
      return (this.adults * 38) + (this.children * 20)
    }
  },
  methods: {
    clearTickets () {
      this.adults = 0
      this.children = 0
    },
    toCheckout () {
      if (this.total > 0) {
        this.checkout = true
      }
    },
    cancelForm () {
      this.checkout = false
    },
    purchase () {
      const toast = useToast()
      this.checkout = 'pending'
      API
      .post ('stripeAPI', '/payment', {
        body: { amount: this.total + '00', currency: 'usd', description: 'Spring Banquet 2022 Tickets - Adults: ' + this.adults + ', Children: ' + this.children}
      })
      .then (res => {
        stripe
        .confirmCardPayment(res.clientSecret, {
          payment_method: {
            card: card,
            billing_details: {
              email: this.email,
              name: this.firstName + ' ' + this.lastName,
            }
          }
        })
        .then(result => {
          if (result.error) {
            toast.error('There was an error processing your payment.', {
              timeout: 3000
            })
          } else {
            toast.success('Payment was successful!', {
              timeout: 3000
            })
            this.checkout = false
          }
        })
      })
    }
  },
  mounted () {
    card.mount('#card-element')
  }
}
</script>

<style lang="css" scoped>
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Mea+Culpa&display=swap');
  .container {
    width: 100vw;
    margin-top: 12rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: rgba(0, 0, 0, 0.75);
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    color: black;
  }

  .header h4 {
    font-size: 4rem;
    font-family: 'Mea Culpa', cursive;
    color: black;
    margin: 0;
    line-height: 60px;
  }

  .header span {
    font-size: 2rem;
    font-family: 'Berkshire Swash', cursive;
    color: black;
  }

  .header p {
    font-family: 'Open Sans', sans-serif;
    text-align: justify;
    font-size: 1.2rem;
    line-height: 30px;
    margin: 1rem 0;
  }

  .donate-box {
    border-radius: 5px;
    box-shadow: 0 0 10px -5px #333;
    border: 1px solid #057c60;
    background-color: white;
    text-align: center;
    width: 40%;
    margin: 4rem 0;
  }

  .title {
    padding: 2rem;
    border-bottom: 1px solid rgba(5, 124, 96, 0.15);
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 2rem;
    box-shadow: 0 3px 5px -6px #333;
  }
  .title p {
    font-size: 1rem;
  }

  .title h4 {
    color: #057c60;
  }

  .description {
    padding: 0 6rem;
    font-size: .9rem;
    line-height: 25px;
    margin-bottom: 2rem;
    opacity: .75;
  }

  .amounts {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: center;
  }

  .amount {
    padding: .5rem 1rem;
    color: #057c60;
    border: 1px solid #057c60;
    border-radius: 5px;
    font-weight: bold;
    margin-right: 2rem;
    cursor: pointer;
    opacity: .75;
  }

  .clear {
    color: red;
    border: 1px solid red;
  }

  .amount p {
  }

  .amount span {
    font-size: .75rem;
  }

  .amount:hover {
    opacity: 1;
  }

  .other-amount {
    padding: .5rem 1rem;
    border: 1px solid rgb(150,150,150);
    border-radius: 5px;
    font-size: 1rem;
    height: 2.3rem;
  }

  .total-area {
    margin-bottom: 1rem;
  }

  .tickets {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ticket {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }

  .ticket input {
    text-align: center;
    border: 1px solid rgba(0,0,0,.25);
    width: 10%;
    font-size: 1.2rem;
  }

  .total {
    margin-top: 2rem;
    font-size: 1.5rem;
  }

  .info {
    padding: 0 4rem;
    font-size: .8rem;
    line-height: 25px;
    margin-bottom: 1rem;
    opacity: .75;
  }

  .checkout {
    background-color: rgba(5, 124, 96, .25);
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 3rem;
  }

  .checkout:hover {
    background-color: rgb(5, 124, 96);
  }

  .checkout:hover p, .checkout:hover i {
    color: white;
  }

  .checkout p {
    margin-right: .5rem;
  }

  .checkout-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .checkout-container .card {
    text-align: left;
    background-color: white;
    box-shadow: 0 0 10px -5px #333;
    border-radius: 5px;
    padding: 2rem 4rem;
    width: 40vw;
  }

  .input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card h4 {
    font-size: 2rem;
    text-align: left;
    margin-bottom: 2rem;
    font-family: 'Montserrat', serif;
  }

  .input-container input {
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 5px;
    padding: .5rem;
    height: 3rem;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 2rem;
    box-sizing: border-box;
    outline: none;
  }

  .input-container input:focus {
    background-color: rgba(5, 124, 96,.25);
    border: 1px solid #057c60;
  }

  .input-field {
    width: 45%;
  }

  .large-field {
    width: 100%;
  }

  .input-field p {
    margin-bottom: 0.5rem;
    text-align: left;
    font-size: .75rem;
    font-weight: bold;
    color: #057c60;
  }

  .astrik {
    font-size: .75rem;
    color: red;
  }

  .card-input p {
    margin-bottom: 1rem;
  }

  #card-element {
    box-shadow: 0 0 10px -5px #333;
    border-radius: 5px;
    border: 1px solid #057c60;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
  }

  .formTotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 2rem;
    padding-top: 1rem;
  }

  .cancel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
  }

  .cancel button {
    background-color: #057c60;
    padding: .85rem;
    color: white;
    border-radius: 5px;
    border: 0;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 0 0 10px -5px #333;
  }

  .stripe-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    width: 100%;
  }

  .stripe-badge img {
    width: 50%;
  }

  .return {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .return i {
    margin-right: .5rem;
  }

  .sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
  }

  .sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
  }
  .sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
          animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  }
  .sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
  }
  .sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
  }
  .sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
  }
  .sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  }
  .sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
  }
  .sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
  }
  @-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
  }

  @keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
  }

  .processing {
  margin-top: 10rem;
  text-align: center;
  }

  .processing p {
  font-size: 1.5rem;
  margin-top: 2rem;
  }

</style>
