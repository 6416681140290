<template>
  <div class="membership">
    <div class="card-container" v-show="checkout === false">
      <div class="card" v-for="info in cardinfo" :key="info.id">
        <div class="membership-info">
          <h4>{{ info.title }}</h4>
          <div class="membership-text">
            <p>{{ info.description }}</p>
            <h5>{{ info.fee }}</h5>
            <span>{{ info.feeType }}</span>
          </div>
          <div class="select-membership" @click="toCheckout(info.buttonText)">
            <p>{{ info.buttonText }}</p><i class="fas fa-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="checkout-container" v-show="checkout === true">
      <form class="card2" method="post" @submit.prevent="processPayment" ref="checkoutForm">
        <div class="form-title">
          <h4>Become A Member</h4>
          <p>Join the DNIMAS Alumni Chapter and help support our future!</p>
        </div>
        <div class="input-container">
          <div class="input-field">
            <p>First name <span class="astrik">*</span></p>
            <input type="text" placeholder="First name" v-model="firstName" required>
          </div>
          <div class="input-field">
            <p>Last Name <span class="astrik">*</span></p>
            <input type="text" placeholder="First name" v-model="lastName" required>
          </div>
        </div>
        <div class="input-container">
          <div class="input-field">
            <p>Email <span class="astrik">*</span></p>
            <input type="email" placeholder="youremail@gmail.com" v-model="email" required>
          </div>
          <div class="input-field">
            <p>Phone Number <span class="astrik">*</span></p>
            <input type="number" placeholder="3013456789" v-model="phone" required>
          </div>
        </div>
        <div class="input-container">
          <div class="input-field small-input">
            <p>Address <span class="astrik">*</span></p>
            <input type="text" placeholder="101 New Court" v-model="street" required>
          </div>
          <div class="input-field small-input">
            <p>City <span class="astrik">*</span></p>
            <input type="text" placeholder="Norfolk" v-model="city" required>
          </div>
          <div class="input-field smaller-input">
            <p>State <span class="astrik">*</span></p>
            <input type="text" placeholder="VA" v-model="state" required>
          </div>
          <div class="input-field smaller-input">
            <p>Zip Code <span class="astrik">*</span></p>
            <input type="number" placeholder="20874" v-model="zipcode" required>
          </div>
        </div>
        <div class="input-container">
          <div class="input-field">
            <p>Graduation Year</p>
            <input type="text" placeholder="1990" v-model="gradYear">
          </div>
          <div class="input-field">
            <p>Password (to access member area) <span class="astrik">*</span></p>
            <input type="password" placeholder="Password" v-model="password" required>
          </div>
        </div>
        <div class="total">
          <p>Your total today:</p>
          <span>{{ '$' + formatTotal + ' ' + membership }}</span>
        </div>
        <div class="card-input">
          <p>Card Number</p>
          <div id="card-element"></div>
        </div>
        <div class="cancel">
          <div class="return" @click="cancelForm">
            <i class="fas fa-arrow-left"></i>
            <p>Return to membership selection</p>
          </div>
          <button ref="submitButton">Complete purchase</button>
        </div>
      </form>
    </div>
    <div class="verification-container" v-show="checkout === 'verification'">
      <div class="verification-text">
        <h4>Verify your new acount.</h4>
        <p>A verification code has been sent to {{email}}. Please enter the code below to finish setting up your account.</p>
        <span>Code may take a minute or two to arrive. Make sure to check spam folder.</span>
      </div>
      <form class="verification-form" @submit.prevent="confirmSignUp">

        <input type="text" v-model="code"><br>
        <button type="submit">Complete signup</button>
      </form>
    </div>
    <div class="processing" v-show="checkout === 'pending'">
      <div class="lds-roller">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      </div>
      <p>{{processText}}</p>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification"
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { createMember } from '@/graphql/mutations';
import * as queries from '@/graphql/queries'

const stripe = window.Stripe('pk_live_51IioEGJLNlPqFgGD9xjg1zs0Cw8n4740q1uNijpBGnTzS3YIZ9rzwhnsMMuRR7bkKoLZ9DTNJXuvScJdpJMGXJW200j7OxDfz7')
const elements = stripe.elements()
const card = elements.create('card')

export default {
  data () {
    return {
      membership: '',
      checkout: false,
      duplicate: false,
      total:'',
      processText: 'Processing payment...',
      firstName: '',
      lastName: '',
      street: '',
      zipcode: '',
      email: '',
      state: '',
      city: '',
      phone: '',
      gradYear: '',
      password: '',
      code: '',
      cardinfo: [
        {
          title: 'Lifetime Membership',
          fee: '$500',
          feeType: 'A one time payment',
          description: 'This membership is a one time payment of the National Membership fee.',
          buttonText: 'Become a lifetime member',
          id: 1
        },
        {
          title: 'Annual Membership',
          fee: '$50',
          feeType: 'A annual payment',
          description: 'This annual membership includes the National and Local Chapter dues.',
          buttonText: 'Become a member',
          id: 2
        },
      ]
    }
  },
  computed: {
    formatTotal () {
      return this.total.toString().slice(0, -2)
    }
  },
  methods: {
    toCheckout (type) {
      if (type === 'Pay Chapter Fee') {
        const toast = useToast()
        toast.info('Please sign into the Members Area to pay chapter fees.')
      } else if (type === 'Become a lifetime member') {
        this.membership = 'Lifetime Membership'
        this.total = 50000
        this.checkout = true
      } else if (type === 'Become a member') {
        this.membership = 'Annual Membership'
        this.total = 5000
        this.checkout = true
      }
    },
    cancelForm () {
      this.checkout = false
    },
    existingMember () {

    },
    processPayment () {
      this.$refs.submitButton.disabled = true
      const toast = useToast()
      let members
      API.graphql(graphqlOperation(queries.listMembers)).then(res => {
        members = res.data.listMembers.items
        if (members.find(x => x.email === this.email)) {
          toast.error(this.email + ' is already a member. Login to the Member Area to make a payment.', {
            timeout: false
          })
          this.$refs.submitButton.disabled = false
        } else {
          this.checkout = 'pending'

          API
          .post ('stripeAPI', '/payment', {
            body: { amount: this.total, currency: 'usd', description: this.membership}
          })
          .then (res => {
            stripe
            .confirmCardPayment(res.clientSecret, {
              payment_method: {
                card: card,
                billing_details: {
                  address: {
                    country: 'US',
                    line1: this.street,
                    city: this.city,
                    postal_code: this.zipcode,
                    state: this.state
                  },
                  email: this.email,
                  name: this.firstName + ' ' + this.lastName,
                  phone: this.phone
                }
              }
            })
            .then(res => {
              if (res.error) {
                toast.error(res.error.message, {
                  timeout: 5000
                })
                this.checkout = true
                this.$refs.submitButton.disabled = false
              } else {
                toast.success('Payment was successful!', {
                  timeout: 3000
                })
                this.processText = 'Creating new member account...'
                Auth.signUp({username: this.email, password: this.password}).then(() => {
                  const member = {
                    id: Math.random().toString().substr(2, 6),
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    address: this.street,
                    city: this.city,
                    state: this.state,
                    zipcode: this.zipcode,
                    phone: this.phone,
                    gradYear: this.gradYear,
                    membership: this.membership,
                    duesPayYear: this.membership === 'Lifetime Membership' ? '' : new Date().getFullYear()
                  }
                  API.graphql(graphqlOperation(createMember, {input: member})).then(() => {
                    this.checkout = 'verification'
                    this.$refs.submitButton.disabled = false
                  }).catch(() => {
                    toast.error('There was an error adding your information.')
                  })
                }).catch(() => {
                  toast.error('There was an error creating your account.')
                })
              }
            })
          }).catch(() => {
            toast.error('There was an error processing the form, please try again')
          })
        }
      })
    },
    async confirmSignUp () {
      const toast = useToast()
      await Auth.confirmSignUp(this.email, this.code).then(() => {
        toast.success('Verification complete! You can now login to the Member Area with your credentials.')
        this.$refs.checkoutForm.reset()
        this.checkout = false
      })
    }
  },
  mounted () {
    card.mount('#card-element')
  }
}
</script>

<style lang="css" scoped>
  .membership {
    position: relative;
    top: 9rem;
    color: rgba(0, 0, 0, 0.75);
    font-family: 'Open Sans', sans-serif;
    padding: 3rem 6rem;
  }

  .membership h3, .membership h2 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: normal;
    color: rgba(0,0,0,.7);
    margin: 0;
  }

  .membership h2 {
    margin: 0;
    font-size: 2.5rem;
    color: black;
    letter-spacing: .25rem;
  }

  .card-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card {
    text-align: center;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #057c60;
    box-shadow: 0 0 10px -5px #333;
    width: 35%;
    margin-top: 2rem;
  }

  .card:nth-child(1) {
    background-color: rgb(5, 124, 96);
    color: white;
    margin-right: 8rem;
  }

  .card:nth-child(1) h4 {
    color: #f3cd31;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }

  .card:nth-child(1) .select-membership {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .card:nth-child(1) .select-membership:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .card:nth-child(1) .select-membership:hover p {
    color: #f3cd31;
  }

  .card:nth-child(1) .select-membership:hover i {
    color: #f3cd31;
  }

  .card2 {
    background-color: white;
    box-shadow: 0 0 10px -5px #333;
    border-radius: 5px;
    padding: 2rem 4rem;
    border: none;
  }

  .membership-info h4 {
    border-bottom: 1px solid rgba(5, 124, 96, 0.15);
    padding: 2rem;
    color: #057c60;
    font-weight: 500;
    font-size: 1.5rem;
    box-shadow: 0 3px 5px -6px #333;
    margin-bottom: 2rem;
  }

  .membership-text {
    padding: 0 4rem;
  }

  .membership-text p {
    font-size: 1rem;
    line-height: 29px;
    margin-bottom: 1rem;
    opacity: .75;
  }

  .membership-text h5 {
    font-size: 4rem;
    margin: 0;
    font-weight: 400;
  }

  .membership-text span {
    font-size: .9rem;
    opacity: .75;
  }

  .select-membership {
    background-color: rgba(5, 124, 96, .25);
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 3rem;
  }

  .select-membership:hover {
    background-color: rgb(5, 124, 96);
  }

  .select-membership:hover p, .select-membership:hover i {
    color: white;
  }

  .select-membership p {
    margin-right: .5rem;
  }

  .checkout-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkout-container .card {
    text-align: left;
    padding: 3rem;
    width: 60%;
  }

  .form-title {
    margin-bottom: 2rem;
  }

  .form-title h4 {
    font-family: 'Montserrat', serif;
    font-size: 2rem;
  }

  .input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .input-container input {
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 5px;
    padding: .5rem;
    height: 3rem;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 2rem;
    box-sizing: border-box;
    outline: none;
  }

  .input-field {
    width: 45%;
  }

  .input-field p {
    margin-bottom: .5rem;
    text-align: left;
    font-size: .8rem;
    font-weight: bold;
    color: #057c60;
  }

  .astrik {
    font-size: .75rem;
    color: red;
  }

  .small-input {
    width: 31%;
  }

  .smaller-input {
    width: 15%;
  }

  .card-input p {
    margin-bottom: 1rem;
  }

  #card-element {
    box-shadow: 0 0 10px -5px #333;
    border-radius: 5px;
    border: 1px solid #057c60;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    background-color: white;
  }

  .total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1rem;
    margin-bottom: 2rem;
    padding-top: 1rem;
  }

  .cancel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
  }

  .cancel button {
    background-color: #057c60;
    padding: .85rem;
    color: white;
    border-radius: 5px;
    border: 0;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 0 0 10px -5px #333;
  }

  .return {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .return i {
    margin-right: .5rem;
  }

.processing {
  margin-top: 10rem;
  text-align: center;
}

.processing p {
  font-size: 1.5rem;
  margin-top: 2rem;
}

.verification-container {
  width: 100%;
  text-align: center;

}

.verification-form {
  margin-top: 4rem;
}

.verification-form input {
  width: 25%;
  margin-right: 1rem;
  font-size: 3rem;
  text-align: center;
  box-shadow: 0 0 10px -5px #333;
  border-radius: 5px;
  border: 1px solid #057c60;
  padding: 1rem .5rem;
}

.verification-form button {
  margin-top: 4rem;
  background-color: #057c60;
  padding: .85rem;
  color: white;
  border-radius: 5px;
  border: 0;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 0 10px -5px #333;
}

.verification-text {
}

.verification-text h4 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.verification-text p {
  font-size: 1.25rem;
  margin-bottom: .5rem;
}

.verification-text span {
  font-size: .9rem;
  font-style: italic;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: black;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
