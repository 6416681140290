/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://t5kd6jpncbhebgovs7ys6odjuq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-phzkldvegnbuzawkmcj2baibt4",
    "aws_cloud_logic_custom": [
        {
            "name": "stripeAPI",
            "endpoint": "https://u9tc4eklld.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:31385fa5-62d1-4198-9147-e4c909f7dfc6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_gg3dJtQ00",
    "aws_user_pools_web_client_id": "58pfh2soklrh62nul2qj0pg7vo",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "dnimas-files141548-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
