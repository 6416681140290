<template lang="html">
  <div class="alumni-spotlight bg-gold">
    <div class="spotlight-box">
      <!-- <p class="this-month">This Month's Spotlight!</p> -->
      <div class="spotlight-img">
        <img src="@/assets/Dionne.jpg">
      </div>
      <h5>Dionne Price</h5>
      <h6>Elected President <br> of American Statistical Association</h6>
    </div>
    <div class="spotlight-txt">
      <div class="textbox">
        <p>Dionne Price, director of Division of Biometrics IV in the Office of Biostatistics, Office of Translational Sciences, Center for Drug Evaluation and Research, US Food and Drug Administration, has been elected the 118th president of the American Statistical Association (ASA). She will serve a one-year term as president-elect beginning January 1, 2022; her term as president becomes effective January 1, 2023.</p>
        <p>Price will be the first African-American president of the ASA. She credits her experience as a summer intern at the National Institutes of Health while an undergraduate student at Norfolk State University in Virginia, one of the country’s historically Black colleges and universities, with exposing her to the work of statisticians and setting her on the path to becoming a statistician herself.</p>
        <p>One of her goals as ASA president is to make sure a variety of young people today have an opportunity similar to hers to see the enormous impact of statisticians. “Although much time has passed since I was an undergraduate, I believe there is still more work to be done in terms of increasing the awareness of statistics as a profession,” Price said. “Undoubtedly, there are untapped quantitative thinkers with great potential to impact the ASA community.” Price thinks it is imperative we highlight the benefits of ASA membership as we also increase awareness of statistics. “The ASA is a community, and one of great value to statisticians,” Price said.</p>
        <p>Price also wants to continue the ASA’s efforts aimed at justice, equity, diversity and inclusion for all. “It is important that we increase our collective awareness and understanding of the experiences of quantitative thinkers from various backgrounds,” she said. She believes these efforts will strengthen our community.</p>
        <p>Price said she intends for the statistical community to continue playing an active role in science policy, advocating for sound statistical theory and ensuring the value of statisticians is recognized. “Throughout 2020, we were constantly presented with data and interpretations of those data pertaining to the coronavirus pandemic,” she said. “Now, more than ever, we must ensure that statistics is properly used to answer questions of interest.”</p>
        <p>In her current role at the US Food and Drug Administration (FDA), Price provides leadership to statisticians developing and applying methodology used in the regulation of drug products. She has been involved in the FDA response to many public health challenges, including serving on the Antibacterial Drug Development Task Force and as a member of the teams reviewing products to treat Ebola and coronavirus infections. She currently leads cross-cutting, collaborative efforts across FDA to better use complex, innovative trial designs in pharmaceutical drug development.</p>
        <p>Please congratulate Dionne on an amazing accomplishment!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.alumni-spotlight {
  width: 100vw;
  height: calc(100vh);
  margin-top: 12rem;
  color: rgba(0, 0, 0, 0.75);
  font-family: 'Open Sans', sans-serif;
}

.spotlight-box {
  text-align: center;
}

.spotlight-box p {
  background-color: #057c60;
  padding: 1rem .5rem;
  border-radius: 5px;
  width: 150px;
  color: white;
  text-align: center;
}

.spotlight-img {
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid #057c60;
  box-shadow: 0 0 10px -5px #333;
  margin: auto;
}

.spotlight-img img {
  width: 100%;
}

.spotlight-box h5 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.spotlight-box h6 {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 0;
}

.spotlight-txt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.textbox {
  width: 100%;
  text-align: center;
  padding: 0rem 20rem;
}

.textbox p {
  margin-bottom: 1rem;
  text-align: justify;
  font-size: 1.2rem;
  line-height: 30px;
}
</style>
