<template>
	<div class="container">
		<form @submit.prevent="setPass">
			<h4>New Password</h4>
			<p>Email</p>
			<input type="email" v-model="email">
			<p>Temporary Password</p>
			<input type="password" v-model="oldPass">
			<p>New Password</p>
			<input type="password" v-model="newPass">
			<div class="submit">
				<button>Set Password</button>
			</div>
		</form>
	</div>
</template>

<script>
	import { Auth } from 'aws-amplify';
	import { useToast } from "vue-toastification";

	export default {
		data () {
			return {
				email: '',
				oldPass: '',
				newPass: '',
			}
		},
		methods: {
			setPass () {
				const toast = useToast()

				Auth.signIn(this.email, this.oldPass).then(user => {
					Auth.completeNewPassword(user, this.newPass).then(() => {
						toast.success('New password has been set. You can now login into the member area!')
						this.$store.commit('signIn', this.email)
						this.$router.push('/Member-Area')
					}).catch(err => {
						console.log(err)
					})
				}).catch(err => {
					console.log(err)
				})
			}
		}
	}
</script>

<style scoped>
	.container {
		width: 100%;
		height: 100vh;
		margin-top: 4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Open Sans', sans-serif;
	}

	form {
		width: 30%;
		background-color: white;
		box-shadow: 0 0 10px -5px #333;
		border-radius: 5px;
		padding: 2rem 4rem;
	}

	form h4 {
		margin-bottom: 2rem;
		font-family: 'Montserrat', serif;
		color: black;
		font-size: 2rem;
	}

	form p {
		margin-bottom: .5rem;
		text-align: left;
		font-size: .9rem;
		font-weight: bold;
		color: #057c60;
	}

	form input {
		border: 1px solid rgba(0,0,0,.15);
		border-radius: 5px;
		padding: .5rem;
		height: 3rem;
		width: 100%;
		font-size: 1rem;
		margin-bottom: 2rem;
		box-sizing: border-box;
		outline: none;
	}

	.submit {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	form button {
		background-color: rgba(5, 124, 96,.9);
		padding: .85rem;
		color: white;
		border-radius: 5px;
		border: 0;
		font-size: .9rem;
		cursor: pointer;
		box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
	}
</style>