<template>
  <div class="container">
    <div class="donate-box" v-show="checkout === false">
      <div class="title">
        <p>Donate to</p>
        <h4>NSUAA DNIMAS Chapter</h4>
        <div class="dropdown">
          <p :class="donationType === 'Chapter Donation' ? 'dropdown-selected' : ''" @click="donationType = 'Chapter Donation'">Chapter Donation</p>
          <p :class="donationType === 'Community Service Project' ? 'dropdown-selected' : ''" @click="donationType = 'Community Service Project'">Community Service Project</p>
        </div>
      </div>
      <div class="description">
        All donations go toward chapter functions, chapter maintenance, NSUAA obligations, and projects that support DNIMAS students.
      </div>
      <div class="amounts">
        <div class="amount" @click="total = 10">
          <p>$10</p>
          <span>USD</span>
        </div>
        <div class="amount" @click="total = 50">
          <p>$50</p>
          <span>USD</span>
        </div>
        <div class="amount" @click="total = 100">
          <p>$100</p>
          <span>USD</span>
        </div>
        <input class="other-amount" type="tel" placeholder="Enter amount" v-model="total">
      </div>
      <div class="total-area">
        <div class="total">
          ${{total}}
        </div>
      </div>
      <div class="info">
        * This is a one time payment
      </div>
      <div class="checkout" @click="toCheckout">
        <p>Proceed to checkout</p><i class="fas fa-arrow-right"></i>
      </div>
    </div>
    <div class="checkout-container" v-show="checkout === true">
      <form class="card" method="post" @submit.prevent="purchase">
        <div class="input-container">
          <div class="input-field">
            <p>First name <span class="astrik">*</span></p>
            <input type="text" placeholder="First name" v-model="firstName">
          </div>
          <div class="input-field">
            <p>Last Name <span class="astrik">*</span></p>
            <input type="text" placeholder="First name" v-model="lastName">
          </div>
        </div>
        <div class="input-container">
          <div class="input-field">
            <p>Email <span class="astrik">*</span></p>
            <input type="text" placeholder="youremail@gmail.com" v-model="email">
          </div>
          <div class="input-field">
            <p>Address <span class="astrik">*</span></p>
            <input type="text" placeholder="101 New Court" v-model="street">
          </div>
        </div>
        <div class="input-container">
          <div class="input-field">
            <p>State <span class="astrik">*</span></p>
            <input type="text" placeholder="VA" v-model="state">
          </div>
          <div class="input-field">
            <p>Zip Code <span class="astrik">*</span></p>
            <input type="text" placeholder="20874" v-model="zipcode">
          </div>
        </div>
        <div class="input-container">
          <div class="input-field">
            <p>Phone Number <span class="astrik">*</span></p>
            <input type="text" placeholder="3013456789" v-model="phone">
          </div>
        </div>
        <div class="formTotal">
          <p>Your total today:</p>
          <span>{{ '$' + formatTotal }}</span>
        </div>
        <div class="card-input">
          <p>Card Number</p>
          <div id="card-element"></div>
        </div>
        <div class="cancel">
          <div class="return" @click="cancelForm">
            <i class="fas fa-arrow-left"></i>
            <p>Return to membership selection</p>
          </div>
          <button>Complete purchase</button>
        </div>
      </form>
    </div>
    <div class="processing" v-show="checkout === 'pending'">
      <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
      </div>
      <p>Processing your payment...</p>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify'
import { useToast } from "vue-toastification"

const stripe = window.Stripe('pk_live_51IioEGJLNlPqFgGD9xjg1zs0Cw8n4740q1uNijpBGnTzS3YIZ9rzwhnsMMuRR7bkKoLZ9DTNJXuvScJdpJMGXJW200j7OxDfz7')
const elements = stripe.elements()
const card = elements.create('card')

export default {
  data () {
    return {
      total: '',
      checkout: false,
      firstName: '',
      lastName: '',
      street: '',
      zipcode: '',
      email: '',
      state: '',
      phone: '',
      cardinfo:[],
      donationType: 'Chapter Donation'
    }
  },
  computed: {
    formatTotal () {
      return this.total.toString().slice(0, -2)
    }
  },
  methods: {
    toCheckout () {
      const toast = useToast()
      if (this.total.toString().indexOf('.') === -1 && this.total > 0) {
        this.checkout = true
        this.total = this.total + "00"
        console.log(this.total);
      } else {
        toast.error('Please use a whole number for the donation amount.', {
          timeout: 5000
        })
      }
    },
    cancelForm () {
      this.total = ''
      this.checkout = false
    },
    purchase () {
      const toast = useToast()
      this.checkout = 'pending'
      API
      .post ('stripeAPI', '/payment', {
        body: { amount: this.total, currency: 'usd', description: this.donationType }
      })
      .then (res => {
        stripe
        .confirmCardPayment(res.clientSecret, {
          payment_method: {
            card: card,
            billing_details: {
              address: {
                country: 'US',
                line1: this.street,
                postal_code: this.zipcode,
                state: this.state
              },
              email: this.email,
              name: this.firstName + ' ' + this.lastName,
              phone: this.phone
            }
          }
        })
        .then(result => {
          if (result.error) {
            toast.error('There was an error processing your payment.', {
              timeout: 3000
            })
            this.checkout = false
            console.log(result.error.message)
          } else {
            toast.success('Payment was successful!', {
              timeout: 3000
            })
            this.checkout = false
          }
        })
      })
    }
  },
  mounted () {
    card.mount('#card-element')
  }
}
</script>

<style lang="css" scoped>
.container {
  width: 100vw;
  height: calc(100vh + 10rem);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.75);
}

.donate-box {
  border-radius: 5px;
  box-shadow: 0 0 10px -5px #333;
  border: 1px solid #057c60;
  background-color: white;
  text-align: center;
  width: 40%;
}

.title {
  padding: 2rem;
  border-bottom: 1px solid rgba(5, 124, 96, 0.15);
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 2rem;
  box-shadow: 0 3px 5px -6px #333;
}
.title p {
  font-size: 1rem;
}

.title h4 {
  color: #057c60;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.dropdown p {
  border: 1px solid #999;
  padding: .5rem 1rem;
  border-radius: 5px;
  margin: 0 1rem;
  cursor: pointer;
}

.dropdown-selected {
  background: #057c60;
  color: #fff;
  border: none !important;
}

.description {
  padding: 0 6rem;
  font-size: .9rem;
  line-height: 25px;
  margin-bottom: 2rem;
  opacity: .75;
}

.amounts {
  padding: 0 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center;
}

.amount {
  padding: .5rem 1rem;
  color: #057c60;
  border: 1px solid #057c60;
  border-radius: 5px;
  font-weight: bold;
  margin-right: 2rem;
  cursor: pointer;
  opacity: .75;
}

.amount p {
}

.amount span {
  font-size: .75rem;
}

.amount:hover {
  opacity: 1;
}

.other-amount {
  padding: .5rem 1rem;
  border: 1px solid rgb(150,150,150);
  border-radius: 5px;
  font-size: 1rem;
  height: 2.3rem;
}

.total-area {
  margin-bottom: 1rem;
}

.total {
  font-size: 4rem;
}

.info {
  padding: 0 4rem;
  font-size: .8rem;
  line-height: 25px;
  margin-bottom: 1rem;
  opacity: .75;
}

.checkout {
  background-color: rgba(5, 124, 96, .25);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 3rem;
}

.checkout:hover {
  background-color: rgb(5, 124, 96);
}

.checkout:hover p, .checkout:hover i {
  color: white;
}

.checkout p {
  margin-right: .5rem;
}

.checkout-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-container .card {
  text-align: left;
  padding: 3rem;
  width: 60vw;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-container input {
  box-shadow: 0 0 10px -5px #333;
  border-radius: 5px;
  border: 1px solid #057c60;
  padding: .5rem;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
}

.input-field {
  width: 45%;
}

.input-field p {
  margin-bottom: .5rem;
}

.astrik {
  font-size: .75rem;
  color: red;
}

.card-input p {
  margin-bottom: 1rem;
}

#card-element {
  box-shadow: 0 0 10px -5px #333;
  border-radius: 5px;
  border: 1px solid #057c60;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
}

.formTotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(5, 124, 96, .25);
}

.cancel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
}

.cancel button {
  background-color: #057c60;
  padding: .85rem;
  color: white;
  border-radius: 5px;
  border: 0;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 0 10px -5px #333;
}

.return {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.return i {
  margin-right: .5rem;
}

.sk-folding-cube {
margin: 20px auto;
width: 40px;
height: 40px;
position: relative;
-webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
float: left;
width: 50%;
height: 50%;
position: relative;
-webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
        transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #333;
-webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
        animation: sk-foldCubeAngle 2.4s infinite linear both;
-webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
-webkit-transform: scale(1.1) rotateZ(90deg);
        transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
-webkit-transform: scale(1.1) rotateZ(180deg);
        transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
-webkit-transform: scale(1.1) rotateZ(270deg);
        transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
-webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
-webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
-webkit-animation-delay: 0.9s;
        animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
0%, 10% {
  -webkit-transform: perspective(140px) rotateX(-180deg);
          transform: perspective(140px) rotateX(-180deg);
  opacity: 0;
} 25%, 75% {
  -webkit-transform: perspective(140px) rotateX(0deg);
          transform: perspective(140px) rotateX(0deg);
  opacity: 1;
} 90%, 100% {
  -webkit-transform: perspective(140px) rotateY(180deg);
          transform: perspective(140px) rotateY(180deg);
  opacity: 0;
}
}

@keyframes sk-foldCubeAngle {
0%, 10% {
  -webkit-transform: perspective(140px) rotateX(-180deg);
          transform: perspective(140px) rotateX(-180deg);
  opacity: 0;
} 25%, 75% {
  -webkit-transform: perspective(140px) rotateX(0deg);
          transform: perspective(140px) rotateX(0deg);
  opacity: 1;
} 90%, 100% {
  -webkit-transform: perspective(140px) rotateY(180deg);
          transform: perspective(140px) rotateY(180deg);
  opacity: 0;
}
}

.processing {
margin-top: 10rem;
text-align: center;
}

.processing p {
font-size: 1.5rem;
margin-top: 2rem;
}
</style>
