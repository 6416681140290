<template>
  <section class="login-area">
    <form class="login-form" @submit.prevent="submit()">
      <p></p>
      <div class="login-box">
        <p>Email <span class="astrik">*</span></p><input v-model="email" type="text" placeholder="Email">
      </div>
      <div class="login-box" v-show="!reset">
        <p>Password <span class="astrik">*</span></p><input v-model="password" type="password" placeholder="Password">
      </div>
      <div class="login-box" v-show="reset">
        <p>Verification Code <span class="astrik">*</span></p><input v-model="code" type="text" placeholder="Verification code">
      </div>
      <div class="login-box" v-show="reset">
        <p>New Password <span class="astrik">*</span></p><input v-model="newPass" type="password"
          placeholder="New Password">
      </div>
      <p class="reset-text">
        Forgot your password? 
        <span class="highlighted" v-show="reset  === false" @click="forgotPass">Reset Password</span>
        <span class="highlighted" v-show="reset  === true" @click="reset = false">Cancel Password Reset</span>
    </p>
      <div class="login-button">
        <p class="new-text">No account? <router-link to="/Become-A-Member"><span class="highlighted">Become a
              member</span></router-link></p>
        <button>{{ !reset ? 'Login' : 'Reset Password' }}</button>
      </div>
    </form>
  </section>
</template>

<script>
import { Auth } from 'aws-amplify'
import { useToast } from "vue-toastification"
const toast = useToast()

export default {
  data() {
    return {
      email: '',
      password: '',
      newPass: '',
      user: '',
      reset: false
    }
  },
  methods: {
    submit() {
      if (this.reset) {
        this.resetPass()
      } else {
        this.login()
      }
    },
    forgotPass() {
      if (this.reset) {
        this.reset = false
      } else {
        this.reset = true
      }

      if (this.email === '') {
        this.reset = false
        toast.error('Please enter your email to reset password', {timeout: 5000})
      } else {
        Auth.forgotPassword(this.email)
          .then((data) => {
            console.log(data)
            const toast = useToast()
            toast.success('A reset verification code will be sent to you remail shortly!', {timeout: false})
          })
          .catch((err) => {
            console.log(err)
            toast.error('There was an error processing your reset, please try again.', {timeout: false})
          });
      }
    },
    resetPass() {
      if (this.email !== '' && this.code !== '' && this.newPass !== '') {
        Auth.forgotPasswordSubmit(this.email, this.code, this.newPass)
          .then((data) => {
            console.log(data)
            toast.success('Your password has been reset!')
            this.reset = false
          })
          .catch((err) => {
            console.log(err)
            toast.error('Password could not be reset, please check that the verification code is correct.', {timeout: false})
          })
      }
    },
    login() {
      const toast = useToast()

      Auth.signIn(this.email, this.password).then(res => {
        console.log(res);
        if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.$router.go('/Set-Password')
        } else {
          this.$store.commit('signIn', res.attributes.email)
          this.$router.push('/Member-Area')
        }
      }).catch(res => {
        toast.error(res.message)
      })
    }
  }
}
</script>

<style lang="css" scoped>
.login-area {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.75);
  font-family: 'Open Sans', sans-serif;
}

.login-area h4 {
  font-size: 3rem;
  padding: 1.25rem;
  text-align: center;
  border-bottom: 4px solid rgba(0, 0, 0, 0.75);
}

.login-area form {
  border-radius: 5px;
  width: 30%;
  height: 20rem;
  padding: 3rem;
}

.login-box {
  width: 100%;
}

.login-box p {
  font-size: 1rem;
  color: #057c60;
  margin-bottom: 1rem;
}

.login-box input {
  box-shadow: 0 0 10px -5px #333;
  border-radius: 5px;
  border: 1px solid #057c60;
  padding: .5rem;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
}

.astrik {
  font-size: .75rem;
  color: red;
}

.reset-text {
  font-size: .75rem;
  color: grey;
  margin-bottom: 3rem;
}

.highlighted {
  color: #057c60;
  cursor: pointer;
}

.highlighted:hover {
  text-decoration: underline;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-text {
  font-size: .9rem;
  color: grey;
}

.login-button button {
  background-color: #057c60;
  padding: .85rem;
  color: white;
  border-radius: 5px;
  border: 0;
  width: 150px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 0 10px -5px #333;
}
</style>
