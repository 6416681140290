<template>
  <nav :class="{ fullnav: openMenu }">
    <div class="nav">
      <div class="nav-title" @click="$router.push('/')">
        <img src="@/assets/nsu-logo.jpeg">
        <span>Dozoretz National Institute for <br>Mathematics and Applied Science Chapter</span>
      </div>
      <div class="small-menu">
        <div class="menu-wrapper" @click="setMenu()" :class="{ active: openMenu }">
          <div class="menu-bar one"></div>
          <div class="menu-bar two"></div>
          <div class="menu-bar three"></div>
        </div>
      </div>
      <div class="menu-items">
        <router-link to="/Become-A-Member">
          <button type="button">Become a member</button>
        </router-link>
        <span>Contact</span>
        <router-link to="">
          <span>News</span>
        </router-link>
        <a href="http://dnimas-nsuaa.com/#news-area">
          <span>Events</span>
        </a>
        <span class="lastmenu">Committees</span>
      </div>
      <div class="member-login">
        <button @click="login" type="button">Member Area</button>
        <!-- <a href="https://www.facebook.com/groups/143605489101699/"><img src="@/assets/fb.png" alt=""></a> -->
        <!-- <a href="https://www.nsu.edu"><img src="@/assets/nsulogo.png" alt=""></a> -->
      </div>
    </div>
    <div class="small-menu-items" v-show="fadeIn">
      <router-link to="/Become-A-Member">
        <button class="button-13" role="button">Become a member</button>
      </router-link>
      <button class="button-13" role="button">Contact</button>
      <router-link to="">
        <button class="button-13" role="button">News</button>
      </router-link>
      <a href="http://dnimas-nsuaa.com/#news-area">
        <button class="button-13" role="button">Events</button>
      </a>
      <button class="button-13" role="button">Committees</button>
      <button class="button-13" role="button" @click="login" type="button">Member Area</button>
    </div>
    <div class="nav-sub">
      <div class="sub-menu">
        <div class="sub-text">
          <p>Alumni Chapter</p>
          <div class="sub-dropdown">
            <router-link to="/Alumni-Spotlight">
              <p>Alumni Spotlight</p>
            </router-link>
            <router-link to="/Executive-Board">
              <p>Executive Board</p>
            </router-link>
            <p>Volunteer</p>
            <p>Stay Connected</p>
          </div>
        </div>
        <router-link to="/Alumni-Events">
          <div class="sub-text">
            <p>Alumni Events</p>
          </div>
        </router-link>
        <router-link to="/Membership-Benefits">
          <div class="sub-text">
            <p>Membership Benefits</p>
          </div>
        </router-link>
        <div class="sub-text">
          <p>Donate</p>
          <div class="sub-dropdown">
            <router-link to="/Cookout-2024">
              <p>DNIMAS Cookout 2024</p>
            </router-link>
            <router-link to="/Spring-Banquet-2022">
              <p>Spring Banquet 2022</p>
            </router-link>
            <router-link to="/Donate">
              <p>Chapter Donation</p>
            </router-link>
            <router-link to="/Endowment-Donation">
              <p>Endowment Donation</p>
            </router-link>
          </div>
        </div>
        <router-link to="/Dr-Dozoretz">
          <div class="sub-text">
            <p>Dr. Dozoretz</p>
          </div>
        </router-link>
      </div>
    </div>
  </nav>

  <router-view v-show="!openMenu" />
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  data() {
    return {
      openMenu: false,
      fadeIn: false
    }
  },
  methods: {
    login() {
      Auth.currentAuthenticatedUser().then(() => {
        this.$router.push('/Member-Area')
      }).catch(() => {
        this.$router.push('/Login')
      })
      /* if (this.$store.state.user !== '') {
        this.$router.push('/Member-Area')
      } else {
        this.$router.push('/Login')
      } */
    },
    setMenu() {
      if (!this.openMenu) {
        this.openMenu = true
        setTimeout(() => {
          this.fadeIn = true
        }, 500)
      } else {
        this.openMenu = false
        this.fadeIn = false
      }
    }
  },
  beforeCreate() {
    if (localStorage.getItem('user')) {
      this.$store.commit('signIn', localStorage.getItem('user'))
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat:500,700|Open+Sans&display=swap');

body {
  margin: 0;
  background-color: #f3cd31;
}

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  margin: 0;
}

a {
  color: white;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0;
}

header {
  width: 100%;
}

h4 {
  margin: 0;
  padding: 0;
}

nav {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 6rem;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 100;
  background-color: white;
  transition: all .5s ease;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.fullnav {
  height: 100dvh;
  display: block;
}

.nav {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6rem;
  box-sizing: border-box;
  padding: 1.5rem;
  color: #057c60;
}

.small-menu-items {
  color: #057c60;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  height: calc(100dvh - 6rem);
}


.small-menu-items p {
  color: #057c60;
  margin-bottom: .5em;
  font-family: 'Montserrat', sans-serif;
  font-size: 2em;
  font-weight: bold;
  cursor: pointer;
}

/* CSS */
.button-13 {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #057c60;
  cursor: pointer;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5em;
  line-height: 29px;
  padding: .5em 1em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 500px;
  margin-bottom: .5em;
}

.button-13:hover {
  background-color: #f7fafa;
}

.button-13:focus {
  border-color: #008296;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  outline: 0;
}

.nsuaa {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.nav-title {
  display: flex;
  align-items: center;
  width: calc(100%/3);
  cursor: pointer;
}

.nav-title img {
  width: 70px;
  margin-right: 1em;
}

.nav-title span {
  font-family: 'Open Sans', sans-serif;
  font-size: .9em;
}

.small-menu {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  display: none;
}

.small-menu img {
  width: 4%;
  cursor: pointer;
}

.menu-wrapper {
  width: 40px;
  height: auto;
  cursor: pointer;
}

.menu-bar {
  width: 100%;
  height: 4px;
  background: #057c60;
  margin-bottom: .5em;
}

.menu-bar:last-child {
  margin: 0 !important;
}

.one {
  animation-delay: 0.1s;
  transition: all 0.3s;
}

.two {
  transition: all 0.3s;
}

.three {
  transition: all 0.3s;
}

.menu-wrapper:hover .menu-bar.active {
  animation: none;
}

.active .one {
  transform: translateY(14px) rotate(45deg);
}

.active .two {
  opacity: 0;
}


.active .three {
  transform: translateY(-10px) rotate(-45deg);
}

.dnimas span {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.menu-items {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100%/3);
}

.menu-items button {
  border: 1px solid #057c60;
  border-radius: 5px;
  padding: .5rem 1rem;
  color: #057c60;
  font-size: .8rem;
  margin-right: 2rem;
  background-color: transparent;
  cursor: pointer;
}

.menu-items span {
  font-family: 'Open Sans', sans-serif;
  font-size: .9em;
  padding-right: 2rem;
  cursor: pointer;
}

.menu-items span:last-child {
  padding-right: 0;
}

.menu-items a {
  color: #057c60;
  text-decoration: none;
  padding-right: 2rem;
}

.member-login {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100%/3);
  cursor: pointer;
  padding-right: 0;
  box-sizing: border-box;
}

.member-login button {
  border-radius: 5px;
  padding: .5rem 1rem;
  border: 0;
  color: black;
  font-size: .9rem;
  background-color: #f3cd31;
  cursor: pointer;
}

.member-login a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.member-login img {
  width: 60%;
}

.nav-sub {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 6rem;
  left: 0;
  width: 100%;
  height: 3rem;
  color: white;
  font-size: .9rem;
  background-color: #057c60;
  box-shadow: 0 5px 5px -5px #111;
}

.sub-menu {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10rem;
}

.sub-text {
  cursor: pointer;
  position: relative;
  width: 200px;
  text-align: center;
}

.sub-dropdown {
  display: none;
  position: absolute;
  background-color: #057c60;
  box-shadow: 0 5px 5px -5px #111;
  padding: 1rem;
}

.sub-dropdown p {
  padding: .5rem .5rem;
  border-bottom: 1px solid #f3cd31;
}

.sub-text:hover .sub-dropdown {
  display: block;
}

.l23 {
  left: 24%;
}

.l57 {
  left: 57%;
}

.green {
  color: #057c60;
}

.gold {
  color: #f3cd31;
}

.bg-gold {
  background-color: #f3cd31;
}

.bg-green {
  background-color: #057c60;
}

@media screen and (max-width: 1650px) {
  .menu-items {
    display: none;
  }

  .member-login {
    display: none;
  }

  .nav-sub {
    display: none;
  }

  .small-menu {
    display: flex;
  }

  .nav-title {
    width: 80%;
  }
}
</style>
