<template>
  <section class="spotlight bg-gold">
    <div class="spotlight-feed">
      <img src="@/assets/Postcard-Back.png">
      <img src="@/assets/Postcard-Front.png">
      <img src="@/assets/sp2.jpg">
      <img src="@/assets/sp3.jpg">
      <img src="@/assets/sp4.jpg">
      <img src="@/assets/sp5.jpg">
      <img src="@/assets/sp6.jpg">
      <img src="@/assets/sp7.jpg">
      <img src="@/assets/sp8.jpg">
      <img src="@/assets/sp9.jpg">
    </div>
  </section>
</template>

<script>

export default {

}

</script>

<style scoped>
  .spotlight {
    width: 100%;
    position: relative;
    top: 9rem;
    color: rgba(0, 0, 0, 0.75);
    font-family: 'Open Sans', sans-serif;
  }

  .spotlight h4 {
    color: black;
    font-size: 3rem;
    padding-left: 4rem;
    padding-top: 1rem;
    text-align: center;
    border-bottom: 4px solid rgba(0, 0, 0, 0.75);
  }

  .spotlight img {
    max-width: 40%;
    margin: 2rem;
    box-shadow: 0 0 10px -5px #333;
  }

  .spotlight-feed {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
</style>
