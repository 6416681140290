<template>
  <section class="dozoretz bg-gold">
    <div class="memorial">
      <a href="https://www.legacy.com/amp/obituaries/dailypress/196167120?utm_source=facebook&utm_medium=social&utm_campaign=obitshareamp&fbclid=IwAR3y3Ku6_U62xxYZIct77nRfYtJdHefAl0wEuB0JRRjGLQEZPi4Le0qw0Is">
        <button type="button">Read the full obituary</button>
      </a>
      <a href="https://www.youtube.com/watch?v=-qP1EZXxZhM&feature=share&fbclid=IwAR2g8tUH8Id1iIl3lOaz2qKWlZ5K_RfmTXDhWe9rIZE08MwZCfItQO1xMlg">
        <button type="button">Watch the memorial</button>
      </a>
    </div>
    <div class="dozoretz-text">
      <p>
        <img class="d-img" src="@/assets/rd.jpg">
        Dr. Ronald I. Dozoretz passed away peacefully on May 8, 2020 at the age of 85. Ron, affectionally known as Dr. D, dedicated his entire life to helping people. He had a very gentle and kind nature; however, he was a strong, confident leader who touched so many family members, communities, and individuals worldwide. <br>
        Ron was born on April 10, 1935 in Buffalo, NY. He graduated from Bennett High School and received his undergraduate degree as well as his medical degree at the University of Buffalo (SUNY at Buffalo). He completed his residency in psychiatry at Case Western Reserve University Hospital. He then served as Lieutenant Commander at the U.S. Naval Hospital in Portsmouth, Virginia. <br>
        Ron was a pioneer in the mental health field. After recognizing the lack of psychiatric hospital beds for mentally ill patients, he founded Center Psychiatrists and Portsmouth Psychiatric Center. Additionally, he created First Hospital Corporation, the first private psychiatric hospital system with close to 20 psychiatric and chemical dependency facilities around the country. Ron was the Founder and Chairman of ValueOptions, Inc., one of the nation's leading behavioral health and wellness companies serving over 38 million people. ValueOptions, Inc. was a leading provider of mental health management services to the Department of Defense, state Medicaid programs, and many Fortune 500 companies. <br>
        Ron was a true philanthropist, impacting numerous communities and individuals. He quietly helped so many people, and had little concern about recognition. He was especially proud of the Dozoretz National Institute for Mathematics and Applied Sciences at Norfolk State University, a scholarship program that sends hundreds of students to graduate programs in applied sciences. Ron was a founding member of the Tidewater Jewish Foundation, the Hebrew Academy of Tidewater, and was a past President of the Medical Society of Virginia. He was also on numerous non-profit boards, some of which included the Kennedy Center, Norfolk State University, and Case Western Reserve University. He was a member of the Board of Trustees for The Field School, Sidwell Friends School, and the Ford's Theatre in Washington, D.C. He was a recipient of the Brotherhood Award from the National Conference of Christians and Jews and the Virginia AFL-CIO Paul Askew Community Service Award. Ron had a long history of involvement in politics, making a large impact on policies and many politicians from local mayors to the Presidents of the United States.
      </p>
    </div>
  </section>
</template>

<script>
export default {
}
</script>

<style scoped>
  .dozoretz {
    width: 100%;
    position: relative;
    top: 9rem;
    color: rgba(0, 0, 0, 0.75);
    font-family: 'Open Sans', sans-serif;
  }

  .dozoretz h4 {
    font-size: 3rem;
    padding: 1rem;
    text-align: center;
    border-bottom: 4px solid rgba(0, 0, 0, 0.75);
  }

  .dozoretz-info {
    text-align: center;
    margin-top: 2rem;
  }

  .d-img {
    box-shadow: 0 0 10px -5px #333;
    float: left;
    clear: right;
    width: 300px;
    margin-right: 2rem;
  }

  .dozoretz-info p {
    font-size: 4rem;
  }

  .dozoretz-info span {
    font-size: 1rem;
  }

  .dozoretz-text {
    text-align: justify;
    font-size: 1.2rem;
    line-height: 30px;
    padding: 2rem 6rem;
  }

  .dozoretz br {
    display: block;
    content: "";
    margin-top: 2rem;
  }

  .memorial {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .memorial button {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    border: 2px solid black;
    background-color: transparent;
    margin: 3rem 4rem;
    cursor: pointer;
  }
</style>
