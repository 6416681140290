import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports';
import '@stripe/stripe-js'
import Toast, { POSITION } from "vue-toastification"
import "vue-toastification/dist/index.css"

const options = {
   position: POSITION.TOP_CENTER
}

Amplify.configure(awsconfig);

createApp(App).use(store).use(router).use(Toast, options).mount('#app')
