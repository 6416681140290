<template>
  <div class="container">
    <div class="landing">
      <!-- <img src="@/assets/img1.jpg"> -->
      <!-- <div class="dark"></div> -->
      <div class="landing-text">
        <div class="mission">
          <p class="tag">Behold, The <span class="green">Green</span> & <span class="gold">Gold</span></p>
        </div>
        <div class="vision">
          <div class="vision-statement">
            <span type="button">Learn more about our objectives <i class="fas fa-arrow-down fa-bounce"></i></span>
          </div>
        </div>
      </div>
    </div>
    <div class="main-area">
      <div class="mission-statement">
        <div class="mission-left">
          <h4>Mission + <span class="gold">Vision</span></h4>
          <p class="mission-text">
            The mission of the NSUAA-DNIMAS Chapter is to promote <span class="gold">strong relationships</span>
            and an avenue by which DNIMAS Alumni may <span class="gold">remain connected</span> to our alma mater,
            one another, and DNIMAS students and staff.
            In addition, it is the desire of the NSUAA-DNIMAS Chapter to identify <span class="gold">prospective candidates</span> for the DNIMAS Program.
          </p>
          <p class="vision-text">
            Our Chapter was founded in 1991 and re-established in 2013 to foster relationships and camaraderie between DNIMAS Alumni.
            The ongoing vision of the NSUAA-DNIMAS Chapter is to engage the DNIMAS Alumni community in activities
            and foster relationships with other Alumni, DNIMAS Students and staff, and the University as a whole.
          </p>
          <router-link to="/Membership-Benefits">
            <button type="button" name="button">See our membership benefits<i class="fas fa-arrow-right"></i></button>
          </router-link>
        </div>
        <div class="mission-right bg-gold">
          <div class="objectives">
            <h4>Objectives</h4>
            <div class="obj-top">
              <div class="obj-card">
                <!-- <img src="obj1.jpg" alt=""> -->
                <h3>Partner</h3>
                <p>To provide a network of DNIMAS Alumni who provide <span class="underline">financial and academic support</span> to DNIMAS students.</p>
              </div>
              <div class="obj-card">
                  <!-- <img src="obj2.jpg" alt=""> -->
                <h3>Advocate</h3>
                <p>To develop and promote <span class="underline">mentoring programs</span> consistent with the goals of the DNIMAS Program to assist students in their educational endeavors.</p>
              </div>
            </div>
            <div class="obj-bottom">
              <div class="obj-card">
                  <!-- <img src="obj3.jpg" alt=""> -->
                <h3>Communicate</h3>
                <p>To act as a liaison between the DNIMAS Alumni and DNIMAS Program to enhance communication, share news and information, and allow for easy access to lifelong <span class="underline">Alumni connectivity and interactions</span>.</p>
              </div>
              <div class="obj-card">
                  <!-- <img src="obj4.jpg" alt=""> -->
                <h3>Engage</h3>
                <p>To be a vessel upon which <span class="underline">positive Alumni and community relationships</span> may be established.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="news-area" class="news bg-green">
        <div class="upcoming-event">
          <h3 class="gold">News & Events</h3>
          <p><span class="underline gold">August 21:</span> Join NSU’s Office of Career Services for a one-on-one virtual professional development session. Alumni can take advantage of exclusive access to resume writing and review, interview techniques, and career resources. Schedule your personal appointment today!</p>
        </div>
        <div class="mini-calendar">
          <div class="event">
            <div class="event-left">
              <p>Club Hot 91</p>
            </div>
            <div class="event-right">
              <span>August 21</span>
            </div>
          </div>
          <div class="event">
            <div class="event-left">
              <p>Walk-In Virtual Career Session</p>
            </div>
            <div class="event-right">
              <span>August 21</span>
            </div>
          </div>
          <div class="event">
            <div class="event-left">
              <p>Alumni Line Dancing 101</p>
            </div>
            <div class="event-right">
              <span>August 25</span>
            </div>
          </div>
          <div class="event">
            <div class="event-left">
              <p>Virtual Series - It's Ok Not To Be Ok</p>
            </div>
            <div class="event-right">
              <span>September 1</span>
            </div>
          </div>
          <div class="event">
            <div class="event-left">
              <p>Virtual Series - Hip Hop Aerobics with NSU</p>
            </div>
            <div class="event-right">
              <span>September 8</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <footer>

      </footer> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home'
}
</script>

<style scoped>
  .landing {
    width: 100%;
    position: relative;
    top: 6rem;
    height: 500px;
    overflow: hidden;
    background-image: linear-gradient(rgba(0, 0, 0, 0.45),
    rgba(0, 0, 0, 0.45)), url("/img1.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
  }

  .landing img {
    position: absolute;
    width: 100%;
    top: -15rem;
  }

  .landing-text {
    width: 100%;
    box-sizing: border-box;
  }

  .dark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    height: 500px;
  }

  .mission {
    width: 100%;
    text-align: center;
  }

  .mission .tag {
    font-size: 4rem;
    font-weight: 700;
    font-style: italic;
  }

  .vision {
    width: 100%;
    text-align: center;
  }

  .vision-statement {
    box-sizing: border-box;
  }


  .vision-statement span {
    font-size: 1.5rem;
    padding-bottom: .5em;
    border-bottom: 2px solid #f3cd31;
    cursor: pointer;
  }

  .vision-statement i {
    font-size: 16px;
    padding-left: 1rem;
  }

  .main-area {
    position: absolute;
    top: calc(500px + 6rem);
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
  }

  .mission-statement {
    display: flex;
    color: rgba(0, 0, 0, 0.75);
    flex-wrap: wrap;
  }

  .mission-left {
    padding: 2rem 4rem;
    width: 50%;
    z-index: 10;
    background-color: white;
    box-sizing: border-box;
  }

  .mission-left h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    border-bottom: 4px solid rgba(0, 0, 0, 0.75);
  }

  .mission-left button {
    border: 0;
    border-bottom: 4px solid #f3cd31;
    padding: 1rem 1rem;
    font-size: 1.5rem;
    background-color: transparent;
    cursor: pointer;
  }

  .mission-left i {
    font-size: 1rem;
    padding-left: 1rem;
  }

  .mission-text  {
    font-size: 19px;
    line-height: 36px;
    margin: 2rem 0;
  }

  .vision-text {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 2rem;
  }

  .mission-right {
    padding: 2rem 4rem;
    width: 50%;
    box-shadow: 0 -5px 10px -5px #333;
    box-sizing: border-box;
  }

  .mission-right h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  .mission-right h3 {
    font-size: 1.2rem;
  }

  .mission-right p {
    font-size: .9rem;
  }

  .obj-top, .obj-bottom {
    display: flex;
    width: 100%;
  }

  .obj-card {
    border: 2px solid rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    margin: 2rem;
    padding: 1rem;
    box-shadow: 0 0 10px -5px #333;
    width: 50%;
  }

  .obj-card img {
    width: 100%;
    box-shadow: 0 0 10px -5px #333;
  }

  .news {
    width: 100%;
  }

  .upcoming-event {
    width: 60%;
    padding: 2rem 4rem;
  }

  .upcoming-event h3 {
    font-size: 3rem;
  }

  .upcoming-event p {
    font-size: 30px;
    line-height: 36px;
    margin: 2rem 0;
  }

  .mini-calendar {
    width: 100%;
  }

  .event {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    font-size: 24px;
    border-bottom: 1px solid rgba(255,255,255, .3);
    cursor: pointer;
  }

  .event:hover {
    background-color: #f3cd31;
    color: #057c60;
  }

  .event:first-child {
    border-top: 1px solid rgba(255,255,255, .3)
  }

  .event-left {
    padding-left: 4rem;
  }

  .event-right {
    padding-right: 4rem;
  }

  @media screen and (max-width: 1350px) {
    .mission-left {
      width: 100% !important;
    }

    .mission-right {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 1000px) {
    .mission p {
      font-size: 3em !important;
    }

    .vision-statement span {
      font-size: 1em !important;
    }
  }

  @media screen and (max-width: 700px) {
    .mission p {
      font-size: 1.5em !important;
    }

    .mission-left h4 {
      font-size: 2em !important;
    }

    .objectives h4 {
      font-size: 2em !important;
    }

    .news-area h3 {
      font-size: 2em !important;
    }

    .mission-text {
      font-size: 1em;
      line-height: 2;
    }

    .vision-text {
      font-size: 1em;
      line-height: 2;
    }

    .mission-left button {
      font-size: 1.25em;
      padding-left: 0;
    }

    .obj-top {
      align-items: center;
      flex-wrap: wrap;
    }

    .obj-bottom {
      align-items: center;
      flex-wrap: wrap;
    }

    .obj-card {
      width: 100% !important;
    }
  }
</style>
