<template lang="html">
  <section class="donate-page">
    <div class="donate-msg">
      <p>
        Thanks so much for your willingness to give back to the DNIMAS Program.  The DNIMAS Endowed Scholarship Fund was specially created so that we (Alumni) could accomplish two tasks at one time.  <b>Task #1:</b> is that the Endowment will help the University should it get into financial trouble.  Universities with Endowments are able to borrow against this money. <b>Task #2:</b> ALL interest that is earned from the Endowment is given to DNIMAS students to assist with their ‘students/family contribution’ portion of their scholarship as the DNIMAS scholarship is no longer a full academic scholarship.  The only time funds are removed from the Endowment (This happens ONCE a year) is when the University distributes the interest earned off the account to the students in August before school starts.
      </p>
      <p>If you are interested in giving an amount on a monthly or biweekly basis (or any other timeframe), you should contact Ms. Misti Goodson (Director of Development Services & Stewardship at NSU) at (757) 823-9068 or <a href="mailto:mgoodson@nsu.edu">mgoodson@nsu.edu</a>.</p>
      <p> To donate online click <a href="https://alumnirelations.nsu.edu/donation-designated-gifts">here</a>.</p>
    </div>
  </section>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>

a {
  color: blue;
  text-decoration: none;
}

.donate-page {
  width: 100vw;
  height: 100vh;
  margin-top: 10rem;
  color: rgba(0, 0, 0, 0.75);
  font-family: 'Open Sans', sans-serif;
}

.donate-msg {
  text-align: justify;
  font-size: 1.2rem;
  line-height: 30px;
  padding: 2rem 10rem;
  margin-top: 1rem;
}

.donate-msg p {
  width: 70%;
  margin: auto;
  margin-bottom: 2rem;
}
</style>
