<template>
  <section class="membership-benefits">
    <ul class="benefit-list">
      <li class="benefit-item" v-for="benefit in benefits" :key="benefit.id">
        <h5>{{ benefit.title }}</h5>
        <p>{{ benefit.description }}</p>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  data() {
    return {
      benefits: [
        {
          title: 'Alumni Chapter Membership',
          description: 'The NSUAA has twenty-four (24) vibrant chapters throughout the country. These chapters provide an endless connection to the SPARTAN NATION and opportunities to create new friends. Visit https://alumnirelations.nsu.edu/nsuaa-leadership to contact the chapter representative near you.',
          id: 1
       },
        {
          title: 'Alumni Travel Discounts',
          description: 'In our continued commitment to our alumni, we have established partnerships with multiple travel service providers that will directly benefit Norfolk State alumni. Visit www.alumnibenefits.org/NSUAA/ for more information.',
          id: 2
       },
        {
          title: 'Barnes & Noble Bookstore',
          description: 'NSU Alumni Association members receive a 10% discount on in-store and online purchases when you present your NSUAA Membership Card at the register. Discount excludes textbooks and any computer department purchases. For more information, call (757) 823-2037 or visit www.norfolkstate.bncollege.com. (This benefit is for paid members ONLY).',
          id: 3
       },
        {
          title: 'GEICO',
          description: 'Norfolk State alumni could receive a special discount on GEICO auto insurance*. GEICO offers great rates, exceptional 24-hour service, fast fair claims handling and high quality auto insurance to over 13.5 million policyholders across the United States. In addition to auto insurance, GEICO can also help insure your motorcycle and the GEICO Insurance Agency can help you with homeowners, renters, condo, boat insurance and more. Visit www.geico.com/alum/nsuaa to find out how much you could save today! (Be sure to mention your NSU affiliation to be eligible for the special savings.) *Discounts and credits are available where state laws and regulations allow and may vary by state.',
          id: 4
        },
        {
          title: 'Lyman Beech Brooks Library',
          description: 'NSU alumni have access to the Lyman Beecher Brooks Library while they are visiting the campus. Resources include checking out books, studying, and use of library computers. Alumni also have access to certain online databases. Present your ID or NSUAA membership card at the front desk to access resources. http://library.nsu.edu/screens/resources_grads.html',
          id: 5
        },
        { title: 'NSU Career Services',
         description: 'NSU Career Services offers a variety of free services to alumni. Located in the Student Services Center, Suite 318, Career Services has a library with information on career options, internships, and current job openings. Whether you\'re a new grad looking for that first professional position, or a seasoned professional looking for a career change, Career Services has resources just for you. For more information call (757) 823-8462 or www.nsu.edu/student-affairs/career-services.',
         id: 6
       },
        {
          title: 'NSU License Plates',
          description: 'Show off your Spartan Pride! Please order Norfolk State University\'s new license plate to help raise money for student scholarships. The license plate fee is $25 annually plus your registration fee. Customize your license plate for an additional $10 personalization fee. (The disabled symbol is also available upon request.) To order the new license plate, visit www.nsu.edu/licenseplate.',
          id: 7
        },
        {
          title: 'Professional and Continuing Education',
          description: 'Improve and expand your performance in your current position or prepare for career advancement and change through professional development programs at Norfolk State University. NSU and the Office of Extended Learning offers affordable, high-quality professional development in both classroom and online continuing education courses, programs, and certificates. Choose from a range of courses and upgrade your skills to gain an advantage in your organization. Start a professional development program at NSU by taking a single course or beginning a certificate program, which can be completed in only a few months. For more information visit https://www.nsu.edu/sel/nsuonline/index.',
          id: 8
        },
        {
          title: 'Request a Transcript',
          description: 'One of the most common questions from alumni is how to obtain a copy (s) of their official transcript. Transcripts can only be issued by the Office of the Registrar. Below is the link to the registrar\'s website that describes the process in full detail. www.nsu.edu/enrollment-management/registrar/verification.',
          id: 9
        },
        {
          title: 'TicketsatWork',
          description: 'NSU Alumni Association members now have access to exclusive offers through TicketsatWork. Members will find exclusive offers on sporting events, ski lift tickets, attractions, movie tickets and much more. You must be a paid member of the alumni association to receive the company identifier.',
          id: 10
        }
      ]
    }
  }
}
</script>

<style scoped>

  .membership-benefits {
    width: 100%;
    position: relative;
    top: 9rem;
    color: rgba(0, 0, 0, 0.75);
    font-family: 'Open Sans', sans-serif;
  }

  .membership-benefits h4 {
    font-size: 3rem;
    padding: 1rem;
    text-align: center;
    border-bottom: 4px solid rgba(0, 0, 0, 0.75);
  }

  .benefit-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    height: 60vw;
    padding: 1rem 3rem;
    list-style: none;
    margin: 0;
  }

  .benefit-item {
    width: 45%;
    padding: 1rem;
    text-align: justify;
  }

  .benefit-item h5 {
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 1rem;
  }
</style>
