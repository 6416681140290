<template lang="html">
  <div class="container">
    <div class="profile-card">
      <div class="card">
        <img class="member-img" :src="profileImg" @click="!icon ? icon = true : icon = false">
        <div class="signout">
          <button v-show="icon" @click="signOut">Sign out</button>
        </div>
        <div class="name">
          <h4>{{ firstName + ' ' + lastName }}</h4>
          <p>{{ email }}</p>
          <span>{{ membership === 'Annual Chapter fee' ? 'Annual Member' : membership }}</span>
          <span class="admin" v-if="admins.includes(email)">Admin</span>
        </div>
        <div class="payment-info">
          <div class="dues-box">
            <div class="year">
              <h5>Annual Membership Fees</h5>
            </div>
            <p class="dues-text">{{ duesText }}</p>
            <span class="pay-button" v-if="paidDues" @click="toCheckout('dues')">Pay dues</span>
          </div>
          <div class="vbar"></div>
          <div class="upgrade-box">
            <h5>Upgrade Membership</h5>
            <p>{{ upgradeText }}</p>
            <span class="pay-button" v-if="membership === 'Annual Membership'"
              @click="toCheckout('upgrade')">Upgrade</span>
          </div>
        </div>
        <div class="checkout-container" v-show="checkout && !pending">
          <div class="dues-checkout">
            <div class="total">
              <p>{{ checkoutText }}</p>
              <span>{{ '$' + formatTotal }}</span>
            </div>
            <div class="card-input">
              <div id="card-element"></div>
            </div>
            <div class="dues-buttons">
              <span @click="checkout = false">Cancel</span>
              <button @click="memberCheckout()" ref="submitButton">Checkout</button>
            </div>
          </div>
        </div>
        <div class="processing" v-show="pending">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p>Processing payment...</p>
        </div>
        <form class="member-info" @submit.prevent="updateInfo">
          <h4>Member Information</h4>
          <div class="input-container">
            <div class="input-field">
              <p>First name</p>
              <input type="text" placeholder="First Name" v-model="firstName">
            </div>
            <div class="input-field">
              <p>Last Name</p>
              <input type="text" placeholder="Last name" v-model="lastName">
            </div>
          </div>
          <div class="input-container">
            <div class="input-field">
              <p>Email</p>
              <input type="text" placeholder="Email" v-model="email">
            </div>
            <div class="input-field">
              <p>Phone Number</p>
              <input type="number" placeholder="Phone" v-model="phone" required>
            </div>
          </div>
          <div class="input-container">
            <div class="input-field small-input">
              <p>Address</p>
              <input type="text" placeholder="Address" v-model="address">
            </div>
            <div class="input-field small-input">
              <p>City</p>
              <input type="text" placeholder="City" v-model="city">
            </div>
            <div class="input-field smaller-input">
              <p>State</p>
              <input type="text" placeholder="State" v-model="state">
            </div>
            <div class="input-field smaller-input">
              <p>ZipCode</p>
              <input type="text" placeholder="ZipCode" v-model="zipcode">
            </div>
          </div>
          <div class="input-container">
            <div class="input-field">
              <p>Graduation Year</p>
              <input type="text" placeholder="Graduation Year" v-model="gradYear">
            </div>
            <div class="input-field">
              <p>Member ID</p>
              <input type="text" placeholder="ID" v-model="id" disabled>
            </div>
          </div>
          <div class="input-container" v-show="changePassword">
            <div class="input-field">
              <p>Verification Code</p>
              <input type="text" placeholder="Enter the code sent to your email here..." v-model="newPassCode">
            </div>
            <div class="input-field">
              <p>New Password</p>
              <input type="text" placeholder="Enter your new password..." v-model="newPassword">
            </div>
          </div>
          <div class="form-footer">
            <p @click="sendPassCode()">Change Password</p>
            <button type="submit">Update Information</button>
          </div>
        </form>
      </div>
    </div>
    <div class="section-container">
      <div class="member-documents">
        <h3>Meeting Minutes</h3>
        <ul>
          <li v-for="doc in meetingMinutes" :key="doc" @click="download('meeting-minutes/' + doc)">
            <img src="@/assets/pdf.png">
            <p>{{ doc }}</p>
          </li>
        </ul>
        <div class="upload" v-if="admins.includes(email)">
          <div>
            <div class="upload-icon">
              <label>
                <input type="file" @change="uploadMinutes($event)" ref="mfile">
                <img src="@/assets/file.png">
              </label>
            </div>
          </div>
          <object height="300" :data="fileUrl" v-show="minutesPreview"></object>
          <button v-show="minutesPreview" @click="sendMinutes">Upload file</button>
        </div>
        <h3>Treasury Reports</h3>
        <ul>
          <li v-for="doc in treasuryReoprts" :key="doc" @click="download('treasury-report/' + doc)">
            <img src="@/assets/pdf.png">
            <p>{{ doc }}</p>
          </li>
        </ul>
        <div class="upload" v-if="admins.includes(email)">
          <div>
            <div class="upload-icon">
              <label>
                <input type="file" @change="uploadTreasury($event)" ref="mfile">
                <img src="@/assets/file.png">
              </label>
            </div>
          </div>
          <object height="300" :data="fileUrl" v-show="treasuryPreview"></object>
          <button v-show="treasuryPreview" @click="sendTreasury">Upload file</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification"
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
const stripe = window.Stripe('pk_live_51IioEGJLNlPqFgGD9xjg1zs0Cw8n4740q1uNijpBGnTzS3YIZ9rzwhnsMMuRR7bkKoLZ9DTNJXuvScJdpJMGXJW200j7OxDfz7')
const elements = stripe.elements()
const card = elements.create('card')
const toast = useToast()

export default {
  data() {
    return {
      meetingMinutes: [],
      treasuryReoprts: [],
      icon: false,
      checkout: false,
      checkoutText: '',
      total: '',
      formatTotal: '',
      admins: [
        'andrew.wilson@rockcreekbusiness.com',
        'mawasw@msn.com',
        'clej2000@yahoo.com',
        'herdbird90@yahoo.com',
        'wlindsay91@yahoo.com'
      ],
      currentUser: '',
      file: '',
      pending: false,
      fileName: '',
      fileUrl: '',
      minutesPreview: false,
      treasuryPreview: false,
      firstName: '',
      lastName: '',
      address: '',
      zipcode: '',
      email: '',
      state: '',
      city: '',
      phone: '',
      gradYear: '',
      id: '',
      membership: '',
      payYear: '',
      changePassword: false,
      newPassCode: '',
      newPassword: '',
      name: this.$store.state.user
    }
  },
  computed: {
    profileImg() {
      return this.membership === 'Lifetime Membership' ? require('@/assets/nsublack.jpeg') : require('@/assets/nsugreen.jpeg')
    },
    duesText() {
      const date = new Date()
      let year = date.getFullYear().toString()
      return this.payYear === year ? 'You have paid your dues for this year, thank you for your support!' : 'You have unpaid dues, please pay below.'
    },
    upgradeText() {
      return this.membership === 'Lifetime Membership' ? 'You are already a Lifetime Member, thank you for your commitment!' : 'Upgrade to a Lifetime Membership to get all the membership benefits for life!'
    },
    paidDues() {
      const date = new Date()
      let year = date.getFullYear().toString()
      return this.payYear === year ? false : true
    }
  },
  methods: {
    signOut() {
      Auth.signOut()
      this.$store.commit('signOut')
      this.$router.push('/Login')
    },
    sendPassCode() {
      this.changePassword = true

      Auth.forgotPassword(this.email).then(data => {
        console.log(data)
        toast.info('A verification code has been sent to your email, submit the code and your new password in the fields below. Then click Update Information', {
          timeout: false,
          closeOnClick: true
        })
      }).catch(err => console.log(err))

    },
    async download(doc) {
      function downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename || 'download'
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url)
            a.removeEventListener('click', clickHandler)
          }, 150)
        };
        a.addEventListener('click', clickHandler, false)
        a.click()
        return a
      }

      let filename = doc.replace('meeting-minutes/', '')
      const result = await Storage.get(doc, { download: true })

      downloadBlob(result.Body, filename)
    },
    toCheckout(type) {
      if (type === 'dues') {
        if (this.membership === 'Lifetime Membership') {
          this.total = 2500
          this.formatTotal = '25'
        } else {
          this.total = 5000
          this.formatTotal = '50'
        }
        this.checkoutText = this.membership + ' Dues'
      } else {
        this.total = 50000
        this.formatTotal = '500'
        this.checkoutText = 'Lifetime Membership Upgrade'
      }
      this.checkout = true
    },
    memberCheckout() {
      const toast = useToast()

      this.pending = true
      this.$refs.submitButton.disabled = false

      API
        .post('stripeAPI', '/payment', {
          body: { amount: this.total, currency: 'usd', description: this.total === 50000 ? 'Membership Upgrade' : 'Member Dues' }
        }).then(res => {
          stripe
            .confirmCardPayment(res.clientSecret, {
              payment_method: {
                card: card,
                billing_details: {
                  address: {
                    country: 'US',
                    line1: this.address,
                    city: this.city,
                    postal_code: this.zipcode,
                    state: this.state
                  },
                  email: this.email,
                  name: this.firstName + ' ' + this.lastName,
                  phone: this.phone
                }
              }
            }).then(res => {
              if (res.error) {
                toast.error(res.error.message, {
                  timeout: 5000
                })
                this.$refs.submitButton.disabled = false
                this.pending = false
              } else {
                toast.success('Payment was successful!', {
                  timeout: 3000
                })

                let newInfo
                let date = new Date()

                if (this.total === 50) {
                  newInfo = {
                    id: this.id,
                    membership: 'Lifetime Membership'
                  }
                } else {
                  newInfo = {
                    id: this.id,
                    duesPayYear: date.getFullYear()
                  }
                }

                API.graphql(graphqlOperation(mutations.updateMember, { input: newInfo })).then(() => {
                  this.$router.go(0)
                }).catch(res => {
                  toast.error(res.message)
                  this.pending = false
                  this.$refs.submitButton.disabled = false
                })

                this.$refs.submitButton.disabled = false
              }
            })
        })
    },
    uploadMinutes(data) {
      this.file = data.target.files[0]
      this.fileName = data.target.files[0].name
      this.fileUrl = URL.createObjectURL(data.target.files[0])
      this.minutesPreview = true
    },
    uploadTreasury(data) {
      this.file = data.target.files[0]
      this.fileName = data.target.files[0].name
      this.fileUrl = URL.createObjectURL(data.target.files[0])
      this.treasuryPreview = true
    },
    async sendMinutes() {
      const toast = useToast()

      await Storage.put('meeting-minutes/' + this.fileName, this.file).then(() => {
        this.$router.go(0)
        toast.success('File successfully added!')
      }).catch(() => {
        toast.error('There was error adding file, please try again.')
      })
    },
    async sendTreasury() {
      const toast = useToast()

      await Storage.put('treasury-report/' + this.fileName, this.file).then(() => {
        this.$router.go(0)
        toast.success('File successfully added!')
      }).catch(() => {
        toast.error('There was error adding file, please try again.')
      })
    },
    updateInfo() {
      if (this.changePassword === true) {
        Auth.forgotPasswordSubmit(this.email, this.newPassCode, this.newPassword)
          .then(() => {
            toast.success('Password has been updated!')
            this.changePassword = false
          })
          .catch(() => {
            toast.error('There was an error updating your password. Please check your information and try again.')
          });
      } else {
        const toast = useToast()
        const newInfo = {
          id: this.id,
          firstName: this.firstName,
          lastName: this.lastName,
          address: this.address,
          zipcode: this.zipcode,
          email: this.email,
          state: this.state,
          city: this.city,
          phone: this.phone,
          gradYear: this.gradYear,
        }

        API.graphql(graphqlOperation(mutations.updateMember, { input: newInfo })).then(() => {
          toast.success('Information updated successfully!')
        }).catch(res => {
          console.log(res);
        })
      }
    }
  },
  async created() {
    let members
    let email
    let user

    await Auth.currentAuthenticatedUser().then(res => {
      email = res.attributes.email
    })

    await API.graphql(graphqlOperation(queries.listMembers)).then(res => {
      console.log(res)
      members = res.data.listMembers.items
      user = members.find(x => x.email === email)
    })

    this.firstName = user.firstName
    this.lastName = user.lastName
    this.state = user.state
    this.membership = user.membership
    this.address = user.address
    this.city = user.city
    this.zipcode = user.zipcode
    this.email = user.email
    this.phone = user.phone
    this.gradYear = user.gradYear
    this.id = user.id
    this.payYear = user.duesPayYear

    await Storage.list('meeting-minutes/').then(res => {
      let list = []
      res.forEach(item => { list.push(item.key) })
      list.shift()
      list = list.map(x => x.replace('meeting-minutes/', ''))
      this.meetingMinutes = list
    }).catch(res => {
      console.log(res);
    })

    await Storage.list('treasury-report/').then(res => {
      let list = []
      res.forEach(item => { list.push(item.key) })
      list.shift()
      list = list.map(x => x.replace('treasury-report/', ''))
      this.treasuryReoprts = list
    }).catch(res => {
      console.log(res);
    })
  },
  mounted() {
    card.mount('#card-element')
  }
}
</script>

<style lang="css" scoped>
.container {
  position: relative;
  top: 12rem;
  font-family: 'Open Sans', sans-serif;
}

.profile-card {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.25rem 4rem;
  color: black;
}

.member-info {
  padding: 2rem;
}

.card {
  width: 60%;
}

.card form {
  background-color: white;
  box-shadow: 0 0 10px -5px #333;
  border-radius: 5px;
  padding: 2rem 4rem;
}

.card form h4 {
  text-align: left;
  margin-bottom: 2rem;
  font-family: 'Montserrat', serif;
}

form button {
  background-color: rgba(5, 124, 96, .9);
  padding: .85rem;
  color: white;
  border-radius: 5px;
  border: 0;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 2rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.card img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid #007A53;
  box-shadow: 0 0 10px -5px #333;
  margin: auto;
  cursor: pointer;
}

.card h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 0;
}

.card .signout {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 5s;
}

.signout button {
  padding: .5rem 1rem;
  background-color: rgba(0, 0, 0, 0.15);
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: .9rem;
}

.name span {
  color: #057c60;
  padding: .5rem 1rem;
  border-radius: 5px;
  font-size: .9rem;
  font-weight: bold;
  background-color: rgba(5, 124, 96, .25);
  text-transform: uppercase;
}

.name {
  margin-bottom: 6rem;
  transition-duration: 2s;
}

.name p {
  font-size: .9rem;
  margin-bottom: 2rem;
}

.name .admin {
  background-color: rgba(219, 58, 52, .25);
  color: #DB3A34;
  margin-left: 1rem;
}

.payment-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.dues-box {
  width: 40%;
}

.dues-box .year {
  margin-bottom: 1rem;
}

.dues-box .dues-text {
  padding: 0 2rem;
}

.checkout-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
}

.dues-checkout {
  width: 50%;
}

#card-element {
  box-shadow: 0 0 10px -5px #333;
  border-radius: 5px;
  border: 1px solid #057c60;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  background-color: white;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.total p {
  margin-bottom: 0 !important;
}

.dues-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dues-buttons button {
  background-color: #057c60;
  padding: 0.85rem;
  color: white;
  border-radius: 5px;
  border: 0;
  font-size: .9rem;
  cursor: pointer;
  box-shadow: 0 0 10px -5px #333;
}

.dues-buttons span {
  cursor: pointer;
}

.year p {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.year .pay-year {
  background-color: rgba(5, 124, 96, .25);
  font-size: 1.5rem;
  font-family: 'Montserrat', serif;
  color: #057c60;
  padding: 1rem;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin: auto;
}

.upgrade-box {
  width: 40%;
}

.payment-info h5 {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 1rem;
}

.upgrade-box p,
.dues-box p {
  margin-bottom: 2rem;
}

.pay-button {
  padding: .5rem;
  background-color: rgba(219, 58, 52, .25);
  color: #DB3A34;
  border-radius: 5px;
  cursor: pointer;
}

.processing {
  margin-bottom: 4rem;
  font-size: 1rem;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.input-container input {
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 5px;
  padding: .5rem;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
  outline: none;
}

.input-container input:focus {
  background-color: rgba(5, 124, 96, .25);
  border: 1px solid #057c60;
}

.input-field {
  width: 48%;
}

.input-field p {
  margin-bottom: .5rem;
  text-align: left;
  font-size: .75rem;
  font-weight: bold;
  color: #057c60;
}

.form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-footer p {
  color: #057c60;
  font-size: .9rem;
  border-bottom: 1px solid #057c60;
  cursor: pointer;
}

.header-buttons {
  display: flex;
  align-items: center;
}

.header-buttons button {
  background-color: white;
  padding: .5rem 1rem;
  color: #057c60;
  border: 1px solid #057c60;
  box-shadow: 0 0 10px -5px #333;
  border-radius: 5px;
  margin-right: 2rem;
}

.header-buttons p {
  cursor: pointer;
}

.header-buttons p:hover {
  text-decoration: underline;
}

.section-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem 4rem;
}

.member-documents {
  color: black;
  width: 60%;
}

.member-documents h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
}

.member-documents ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  max-height: 500px;
  overflow-x: scroll;
}

.member-documents li {
  padding: 2rem;
  width: 25%;
  list-style: none;
  background-color: white;
  box-shadow: 0 0 10px -5px #333;
  border-radius: 5px;
  margin-right: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.member-documents img {
  width: 20%;
}

.member-documents p {
  word-wrap: break-word;
}

.upload {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 8rem;
}

.upload input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload label {
  cursor: pointer;
}

.upload span {
  font-size: .8rem;
  text-align: center;
}

.upload object {
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 10px -5px #333;
  margin-right: 2rem;
}

.upload button {
  background-color: #057c60;
  padding: 0.85rem;
  color: white;
  border-radius: 5px;
  border: 0;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 0 10px -5px #333;
}

.upload-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(5, 124, 96, .25);
  padding: .5rem;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-right: 2rem;
}

.upload-icon img {
  width: 70%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}

.small-input {
  width: 31%;
}

.smaller-input {
  width: 15%;
}

.flex {
  display: flex;
}

.center {
  align-items: center;
  justify-content: center;
}

.right {
  align-items: center;
  justify-content: flex-end;
}

.vbar {
  width: 2px;
  height: 150px;
  background-color: rgba(5, 124, 96, .25);
}

.lightred {
  background-color: rgba(219, 58, 52, .25);
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: black;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
