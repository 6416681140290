import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      user: '',
      memberCheckout: '',
    }
  },
  mutations: {
    signIn (state, userInfo) {
      localStorage.setItem('user', userInfo)
      state.user = userInfo
    },
    signOut (state) {
      localStorage.clear()
      state.user = ''
    },
    setCheckout (state, type) {
      state.memberCheckout = type
    }
  },
  actions: {
  },
  modules: {
  }
})
