<template lang="html">
  <section class="directors bg-gold">
    <ul class="director-list">
      <div class="card-test">
        <li class="director-card">
          <div class="d2">
            <img src="@/assets/bod_Cle.jpg">
          </div>
          <div class="d1">
            <p class="director-name">Cle Jones</p>
            <p class="director-title">President</p>
            <p class="director-bio">
              Cle graduated in 1991 as a DNIMAS student in electrical engineering. After NSU, he attended graduate school at Penn State University, and received his MS in electrical engineering. He worked as an electrical engineer in private industry for 15 years before joining the FBI, where he has worked for the past 15 years and now serves as assistant section chief of the National Capital Region. He married fellow DNIMAS classmate, Dameron Jones, and they have two children, Joshua and Naomi.
            </p>
          </div>
        </li>
        <li class="director-card">
          <div class="d2">
            <img src="@/assets/yolanda.jpg">
          </div>
          <div class="d1">
            <p class="director-name">Yolanda Ward</p>
            <p class="director-title">Secretary</p>
            <p class="director-bio">Yolanda is a Lifetime Member of the DNIMAS Alumni Association, serving as Vice President for the past two years. She was a member of the first DNIMAS class in '90 and has a B.S. in Computer Science. After leaving Norfolk State University, she received an M.S. from Michigan State University in '92. During her tenure at Norfolk State and Michigan State, she worked for multiple years at the National Institute of Standards and Technology in Gaithersburg, MD. The experience obtained during those internships helped lead her to a full time position at LexisNexis, where she has been employed for the last 27 years. She currently serves as a Senior Project Manager reporting directly to the Vice President - Chief Information Security Officer.</p>
          </div>
        </li>
      </div>
      <div class="card-test">
        <li class="director-card">
          <div class="d2">
            <img src="@/assets/andria.jpg">
          </div>
          <div class="d1">
            <p class="director-name">Andria Chapman-Taliaferro</p>
            <p class="director-title">Vice President</p>
            <p class="director-bio">Dr. Andria Chapman-Taliaferro is a1993 graduate of DNIMAS, with a BS in Biology. She is a lifetime member of the DNIMAS Alumni Chapter.  Andria was NSU Ms. Alumni 2019-2020.
In 1993, she was initiated into the Epsilon Theta Chapter of Delta Sigma Theta Sorority, She is currently a member of the Hampton Alumnae Chapter and serves as a board member of the Hampton Delta Foundation.

Dr. Chapman-Taliaferro earned a Doctor of Dental Surgery Degree from Virginia Commonwealth University Medical College of Virginia School of Dentistry in 1997 and is currently practicing in the city of Newport News. A career in general dentistry gives her the opportunity to provide a much needed service that gives a long lasting first impression:  a beautiful smile.

Andria is the mother of five fabulous daughters.  Two of whom graduated from NSU.</p>
          </div>
        </li>
        <li class="director-card">
          <div class="d2">
            <img src="@/assets/Walter.jpg">
          </div>
          <div class="d1">
            <p class="director-name">Walter Lindsay</p>
            <p class="director-title">Treasurer</p>
            <p class="director-bio">Walter graduated from DNIMAS with a B.S. Physics in physics in May of 1995.  He went on to receive his MS in Materials Science in May of 1999. Upon graduation, he took a job wit the Tim Reid Scholarship Foundation, serving as assistant director for two years.  He has subsequently worked for the US Patent and Trademark Office since 1999, serving as a Supervisory Patent Examiner since  2012.  Walter has previously served as DNIMAS alumni chapter president and treasurer.  He is the father of two daughters.</p>
          </div>
        </li>

      </div>
      <!-- <li class="director-card">
        <div class="d1">
          <p class="director-name">Dameron Jones</p>
          <p class="director-title">Annual Giving Chair</p>
          <p class="director-bio"></p>
        </div>
        <div class="d2">
          <img src="dameron.jpeg">
        </div>
      </li> -->
    </ul>
  </section>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.directors {
  width: 100%;
  min-height: 900px;
  position: relative;
  top: 9rem;
  color: rgba(0, 0, 0, 0.75);
  font-family: 'Open Sans', sans-serif;
}

.directors h4 {
  font-size: 3rem;
  padding-left: 4rem;
  padding-top: 1rem;
  text-align: center;
  border-bottom: 4px solid rgba(0, 0, 0, 0.75);
}

.directors ul {
  padding: 0 2rem;
}

.directors li {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1rem;
  margin-right: 2rem;
  border-bottom: 1px solid black;
}

.d1 {
  width: 80%;
  margin-bottom: 2rem;
}

.d2 {
  width: 20%;
  margin: auto;
  margin-right: 4rem;
}

.card-test {
  display: flex;
  align-items: center;
}

.directors img {
  width: 100%;
  box-shadow: 0 0 10px -5px #333;
}

.director-name {
  font-size: 2rem;
  font-weight: bold;
}

.director-title {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 1rem;
}

.director-bio {
  width: 100%;
  height: 270px;
  overflow: scroll;
  text-align: justify;
}
</style>
