import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import Login from '../views/Login.vue'
import News from '../views/News.vue'
import BecomeAMember from '../views/BecomeAMember.vue'
import Committees from '../views/Committees.vue'
import MembershipBenefits from '../views/MembershipBenefits.vue'
import Donate from '../views/Donate.vue'
import AlumniEvents from '../views/AlumniEvents.vue'
import DrDozoretz from '../views/DrDozoretz.vue'
import MemberPage from '../views/MemberPage.vue'
import SpringBanquet from '../views/SpringBanquet.vue'
import Cookout from '../views/Cookout.vue'
import Cookout23 from '../views/Cookout23.vue'
import AlumniSpotlight from '../views/AlumniAssociation/AlumniSpotlight.vue'
import ExecutiveBoard from '../views/AlumniAssociation/ExecutiveBoard.vue'
import EndowmentDonation from '../views/AlumniAssociation/EndowmentDonation.vue'
import SetPassword from '../views/SetPassword.vue'

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/Login',
    component: Login
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },
  {
    path: '/Become-A-Member',
    component: BecomeAMember
  },
  {
    path: '/Committees',
    name: 'Committees',
    component: Committees
  },
  {
    path: '/Membership-Benefits',
    component: MembershipBenefits
  },
  {
    path: '/Donate',
    name: 'Donate',
    component: Donate
  },
  {
    path: '/Alumni-Events',
    component: AlumniEvents
  },
  {
    path: '/Dr-Dozoretz',
    name: 'DrDozoretz',
    component: DrDozoretz
  },
  {
    path: '/Member-Area',
    component: MemberPage
  },
  {
    path: '/Spring-Banquet-2022',
    component: SpringBanquet
  },
  {
    path: '/Cookout-2022',
    component: Cookout
  },
  {
    path: '/Cookout-2024',
    component: Cookout23
  },
  {
    path: '/Alumni-Spotlight',
    component: AlumniSpotlight
  },
  {
    path: '/Executive-Board',
    component: ExecutiveBoard
  },
  {
    path: '/Endowment-Donation',
    component: EndowmentDonation
  },
  {
    path: '/Set-Password',
    component: SetPassword
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
